import et from 'vee-validate/dist/locale/et.json'
export default (context) => {
  return new Promise(function (resolve) {
    resolve({
      meta: {
        title: 'Piletid kontsertidele, etendustele ja muudele üritustele'
      },
      validation: {
        ...et.messages,
        phone: 'Sellel väljal peab olema kehtiv telefoninumber',
        password: 'Paroolid ei ühti',
        payment_method: 'Palun valige soovitud makseviis'
      },
      errors: {
        basket: {
          ticket_cant_be_reserved: 'See pilet on juba kellegi poolt broneeritud'
        },
        checkout: {
          email_invalid: 'Email is invalid',
          email_domain_invalid: 'Email domain is invalid',
          surname_too_short: 'Surname is too short',
          name_too_short: 'Name is too short'
        },
        promo_code_not_found: 'Sooduskoodi rakendamine ebaõnnestus'
      },
      cookies: {
        content: `See veebisait kasutab küpsiseid, mis on absoluutselt vajalikud selle korrektseks toimimiseks. Lisateabe saamiseks tutvuge palun meie küpsiste kasutamise poliitikaga. Kui vajalikke küpsiseid ei kasutata, võib esineda olukord, kus mõned teenused ja veebisaidi funktsioonid ei tööta. Kasutaja kogemuse ja teenuste kvaliteedi parandamiseks kasutab veebisait järgmisi tüüpi küpsiseid: analüütilised küpsised, mis analüüsivad ja parandavad veebisaidi jõudlust, sotsiaalmeedia küpsised, mis võimaldavad sisu jagamist sotsiaalvõrgustikes, ning turundusküpsised.`,
        title: `Jätkates veebisaidi kasutamist, palun kinnitage, kas nõustute küpsiste kasutamisega.`,
        required: 'Vajalikud küpsised',
        statistics: 'Statistilised küpsised',
        social: `Sotsiaalmeedia küpsised (Peab olema märgitud, kui soovite jagada lehe sisu sotsiaalvõrgustikes) Sotsiaalmeedia küpsised (Peab olema märgitud, kui soovite jagada lehe sisu sotsiaalvõrgustikes)`,
        marketing: 'Turundusküpsised'
      },
      password: {
        weak: 'Nõrk',
        fair: 'Keskmine',
        good: 'Hea',
        strong: 'Tugev',
        excellent: 'Kontserdid'
      },
      menuTitles: {
        concerts: 'Kontserdid',
        theater: 'Teater',
        festivals: 'Festivalid'
      },
      buttons: {
        save: 'Salvesta',
        change_password: 'Muuda parool',
        sign_in: 'Logi sisse',
        sign_up: 'Registreeru',
        load_more: 'Lae rohkem',
        retry_purchase: 'Proovi uuesti osta',
        fix_world: 'Proovi maailma parandada',
        float_away: 'Hõlju minema',
        apply: 'Rakenda',
        clear: 'Tühjenda',
        read_more: 'Rohkem infot',
        tickets_and_more: 'Piletid',
        enter: 'Sisenema'
      },
      modal: {
        cookies_updated: 'Teie küpsiseid värskendati!',
        request_submitted: 'Teie taotlus esitati edukalt',
        password_changed: 'Parool on muudetud!',
        personal_data_updated: 'Isikuandmeid uuendati!'
      },
      search: {
        search: 'Otsi',
        placeholder: 'Otsi sündmusi, toimumiskohti',
        view_all_results: 'Kuva kõik',
        no_results_found: 'Tulemusi ei leitud'
      },
      headings: {
        presale_code: 'Eelmüügi kood...',
        about: 'Teave',
        success: 'Edukas!',
        sign_in: 'Logi sisse',
        sign_in_with: 'Logi sisse kontoga',
        sign_up_with: 'Registreeru kontoga',
        password_change: 'Parooli muutmine',
        recommended: 'Soovitatav',
        related: 'Seotud sündmused',
        latest: 'Uusimad',
        news: 'Uudised',
        rescheduled: 'Muudetud',
        cancelled: 'Tühistatud',
        username: 'Kasutajanimi',
        old_password: 'Vana parool',
        new_password: 'Uus parool',
        repeat_password: 'Korda parooli',
        password: 'Parool',
        forgotpass: 'Unustasin parooli',
        iagree: 'Nõustun',
        name: 'Nimi',
        surname: 'Perekonnanimi',
        email: 'Meiliaadress',
        phone: 'Telefon',
        or: 'või',
        newuser: 'Uus kasutaja',
        userhaveaccount: 'On juba konto',
        customersupport: 'Klienditugi',
        payment: 'Makse',
        reset_password: 'Parooli lähtestamine',
        reset_password_l2:
          'Ärge kartke. Saadame teile meili teel juhised parooli lähtestamiseks.',
        categories: 'Kategooriad',
        events: 'Üritused',
        logging_out: 'Väljalogimine',
        payment_failed: 'Makse ebaõnnestus',
        payment_success: 'Maksmine õnnestus!',
        search_results_for: 'Otsingutulemused'
      },
      badges: {
        soldout: 'Välja müüdud',
        premiere: 'Esietendus'
      },
      navigation: {
        information: 'Teave',
        faq: 'KKK',
        terms: 'Pileti ostmise reeglid',
        tickets: 'Piletid',
        etickets: 'E-piletid',
        payments: 'Maksed',
        privacy: 'Privacy policy',
        cookies: 'Küpsiste kasutamise poliitika',
        contacts: 'Kontaktid',
        news: 'Uudised',
        partners: 'Partnerid',
        about: 'Meist',
        authorize: 'Logi sisse',
        register: 'Registreeru',
        userprofile: 'Profiil',
        pay: 'Maksa',
        apply: 'Rakenda',
        logout: 'Välju',
        usertickets: 'Minu piletid',
        backToTickets: 'Tagasi piletite juurde',
        backToEventInfo: 'Tagasi ürituse teabe juurde',
        back: 'Back',
        gotopayment: 'Mine makse juurde',
        retry_trans: 'Proovi tehingut uuesti',
        closeCart: 'Sulge ostukorvi',
        remove: 'Eemalda',
        insurance: 'Kindlustustingimused',
        publiccontract: 'Piletimüügilepingu üldtingimused'
      },
      labels: {
        company: 'Ettevõtte nimi',
        email: 'Meiliaadress',
        confirm_email: 'Confirm E-mail',
        legalAddress: 'Juriidiline aadress',
        name: 'Nimi',
        optional: '(valikuline)',
        phone: 'Telefon',
        regnr: 'Reg. nr.',
        required: '(nõutav)',
        surname: 'Perekonnanimi',
        vat: 'Käibemaksukohustuslasena registreerimise nr.'
      },
      misc: {
        support_email: 'Support email',
        tickets_left: 'Tickets available',
        choose_time: 'Choose time',
        select_your_branch: 'Select Your branch',
        back_to_all_payment_methods: 'Back to all payment methods',
        accept: 'Nõustu',
        additionalOptions: 'Täiendavad seaded',
        langselector: 'Eesti',
        withuseragreement: 'kasutustingimused',
        price_from: 'alates',
        from: 'alates',
        to: 'kuni',
        price: 'hind',
        soldout: 'välja müüdud',
        discount: 'Allahindlus',
        download: 'Laadi alla',
        event_name: 'Sündmuse nimi',
        loading: 'Tellimuse töötlemine',
        processing_order: 'Processing order',
        load_more: 'Lae rohkem',
        selectDiscount: 'Valige allahindlus',
        sevice_fee_and_vat: 'Teenustasu (sisaldab käibemaksu)',
        fixed_service_fee: 'Koha teenustasu (sisaldab käibemaksu)',
        ticket_details: 'Pileti üksikasjad',
        ticket_id: 'Pileti ID',
        ticket_no: 'Pilet nr',
        ticket_type: 'Pileti tüüp',
        tickets: 'piletid | pilet | piletid',
        total: 'Kokku',
        buy_ticket: 'Osta pilet',
        add_to_cart: 'Lisa ostukorvi',
        standticket: 'Seisupiletid',
        seatticket: 'Istekohaga piletid',
        close: 'Sulge',
        required_fields: 'Nõutud väljad',
        at: '',
        no_tickets: 'Pileteid pole',
        order_id: 'Tellimuse ID',
        purchased: 'Ostetud',
        download_invoice: 'Laadi arve alla',
        download_pdf: 'Laadi alla PDF',
        not_available: 'pole saadaval',
        work_hours: 'tööaeg: "E - P“,',
        doors: 'Uksed',
        start: 'Algus',
        age_restriction: 'Vanusepiirang',
        tags: 'Sildid',
        by: '',
        organised_by: 'korraldaja',
        sector: 'SEK'
      },
      ticketselector: {
        SALES_NOT_STARTED: {
          title: 'Müük pole veel alanud',
          subtitle: ''
        },
        SALES_ENDED: {
          title: 'Müük on lõppenud',
          subtitle: ''
        },
        AVAILABLE: {
          title: '',
          subtitle: ''
        },
        SOMEONE_IS_BUYING: {
          title: 'Keegi ostab viimaseid pileteid',
          subtitle: 'Võib-olla mõned piletid saavad vabaks mõne minuti pärast'
        },
        OWN: {
          title: '',
          subtitle: ''
        },
        SOLDOUT: {
          title: 'VÄLJA MÜÜDUD',
          subtitle: ''
        }
      },
      text: {
        password_reset_email_sent:
          'Parooli muutmise juhised on saadetud märgitud e-posti aadressile.',
        password_has_been_changed: 'Teie parool on edukalt muudetud!',
        agreeOnTerms: {
          text: 'Olen tutvunud ja nõustun {link}!',
          link: 'e-poe kasutamise ja piletikindlustuse tingimustega (punkt 5.7)'
        },
        cart_is_empty: 'Ostukorv on tühi',
        enter_promo_code: 'Sisestage sooduskood',
        stripeDescription:
          'Teil on makse sooritamiseks aega 10 minutit. Vastasel juhul tehing tühistatakse ja teie broneeritud piletid muutuvad vabalt ostmiseks kättesaadavaks.',
        fdataDescription:
          'Teil on makse sooritamiseks aega 10 minutit. Vastasel juhul tehing tühistatakse ja teie broneeritud piletid muutuvad vabalt ostmiseks kättesaadavaks.',
        i_have_promocode: 'Mul on sooduskood',
        promocode_activated: 'Sooduskood on aktiveeritud!',
        promocode_apply_fail: 'Sooduskoodi rakendamine ebaõnnestus',
        newsAndOffers: 'Kas soovite saada uudiseid ja häid piletipakkumisi?',
        purchaseAsLegal: 'Ostke juriidilise isikuna',
        swedDescription:
          '<b>TSee makseviis on saadaval ainult Swedbank Läti klientidele.</b> Teil on makse sooritamiseks aega 10 minutit. Vastasel juhul tehing tühistatakse ja teie broneeritud piletid muutuvad vabalt ostmiseks kättesaadavaks.',
        payment_failed_w_error: 'Makse ebaõnnestus veakoodiga',
        invoice_copy_sent: 'Piletid ja arve on saadetud teie meiliaadressile:',
        invoice_copy_will_be_sent:
          'Hetke pärast saadetakse piletid teie määratud e-posti aadressile.',
        user_exists_1: 'Selle meiliaadressiga kasutaja on juba olemas. Palun ',
        user_exists_2: 'logige sisse',
        user_exists_3: ' või ',
        user_exists_4: 'taastage parool',
        something_wrong: 'Midagi läks väga valesti',
        page_not_found: 'Lehte ei leitud',
        no_events_available: 'Ühtegi üritust pole saadaval',
        order_completed_1: 'Teie tellimus',
        order_completed_2: 'piletitele',
        order_completed_3: 'on lõpule viidud',
        processing_tickets: 'Piletite töötlemine',
        choose_payment_country:
          'Valige, millise riigi panku / maksesüsteeme kasutada.'
      },
      titles: {
        agreement: 'Kokkulepe',
        buyer: 'Ostja',
        paymentMethod: 'Makseviis',
        purchased_tickets: 'Download Your tickets',
        your_tickets: 'Teie piletid'
      },
      filters: {
        all_dates: 'Kõik kategooriad',
        all_categories: 'Kõik kategooriad',
        category_selected: 'Valitud kategooria',
        pick_category: 'Valige kategooria',
        all_locations: 'Kõik asukohad',
        pick_country: 'Select country',
        location_selected: 'asukoht valitud',
        pick_location: 'asukoht valitud',
        pick_another_date: 'Valige teine ​​kuupäev'
      },
      fdata: {
        100: 'Tagasi lükatud (üldine, kommentaare pole)',
        101: 'Tagasi lükatud, aegunud kaart',
        102: 'Tagasi lükatud, pettuse kahtlus',
        104: 'Tagasi lükatud, piiratud kaart',
        105: 'Tagasi lükata, kaardi vastuvõtja helistab hankija turvaosakonda',
        106: 'Tagasi lükatud, lubatud PIN-koodi katsed on ületatud',
        111: 'Tagasi lükatud, vale kaardinumber',
        116: 'Tagasi lükatud, kaardi kirje puudub',
        118: 'Tagasi lükatud, puuduvad vabad vahendid',
        119: 'Tagasi lükatud, tehing pole kaardiomanikule lubatud',
        120: 'Tagasi lükatud, tehing pole terminalile lubatud',
        121: 'Tagasi lükatud, ületab väljamakse summa limiiti',
        122: 'Tagasi lükatud, turvarikkumine',
        123: 'Tagasi lükatud, ületab väljamakse sageduse limiiti',
        129: 'Tagasi lükatud, võltsitud kaardi kahtlus',
        204: 'Kaardi ära võtmine, piiratud kaart',
        208: 'Kaardi ära võtmine, kaotatud kaart',
        902: 'Tagasilükkamise põhjuse teade: kehtetu tehing',
        904: 'Tagasilükkamise põhjuse teade: vormingu viga',
        908: 'Tagasilükkamise põhjuse teade: tehingu sihtkohta ei leitud marsruutimiseks',
        909: 'Tagasilükkamise põhjuse teade: süsteemi rike',
        911: 'Tagasilükkamise põhjuse teade: kaardi väljastaja aegumine',
        912: 'Tagasilükkamise põhjuse teade: kaardi väljastaja pole kättesaadav',
        940: 'Tagasi lükatud, pettuse filtri poolt blokeeritud'
      },
      eventLabels: {
        postponed: 'Uus kuupäev on peagi käes!',
        suspended: 'Selle sündmuse müük on peatatud kuni tulevase teatamiseni.',
        preorder: 'Müügi algus <b class="color--text-blue wsnw">{from}</b>',
        public_preorder:
          'Avaliku eelmüügi algus: <b class="color--text-blue wsnw">{from}</b>'
      },
      eventLabelsButton: {
        preorder: 'Saadaval läbi {0}',
        onlinesaleended: 'Internetikauplemine on suletud'
      },
      presale: {
        early_access: 'Varajase juurdepääsu eelmüük',
        early_access_tooltip:
          'Kui teil on spetsiaalne eelmüügikood, saate selle ürituse pileteid osta enne avaliku müügi algust. Eelmüügikoode jagab artist ja/või ürituse korraldaja. Ticketshop pole neid koode saadaval.'
      },
      countdown: {
        days: 'p.',
        hours: 'tun.',
        minutes: 'm.',
        seconds: 't.'
      },
      insurance: {
        available: 'Piletikindlustus saadaval',
        tickets: 'Piletikindlustus',
        title: 'OLGE TURVALINE JA VALIGE PILETIKINDLUSTUS!',
        description:
          'Kahjud hüvitatakse, kui Teile juhtub õnnetus või te ei saa tervisega seotud piirangute tõttu üritusele tulla või juhul, kui üritus tühistatakse / edasi lükatakse ning uued muudatused ei sobi teile üldse.',
        details: 'Lisateave kindlustustingimuste kohta.',
        secure_tickets: 'Kindlustage piletid',
        continue_without: 'Jätka ilma kindlustuseta',
        cost: 'KINDLUSTUSE MAKSUMUS',
        secure_order: 'Kindlustage ost',
        cancel: 'Tühista kindlustus',
        ticket_secured: 'Pilet on kindlustatud',
        ticket_unavailable: 'Kindlustust ei ole saadaval',
        provider: 'BALCIA KINDLUSTUS',
        info: {
          title: 'Hüvitame Teie kahjud, kui:',
          list: [
            'Teile tekib probleem ja Te ei saa üritusele tulla;',
            'Covid-19 või muud tervisepiirangud segavad üritusele tulekut;',
            'Üritus tühistatakse või lükatakse edasi ning uus aeg või koht Teile ei sobi.'
          ]
        }
      },
      currencyModal: {
        title: 'SUL ON LÕPETAMATA OST {cartCurrency} VALUUTAS',
        subtitle:
          'Üritus, mille jaoks soovid pileteid valida, on {eventCurrency} valuutas. Praegu on sul ostukorvis piletid, mille hind on määratud {cartCurrency} valuutas. Sul on vaja lõpetada olemasolev ost või tühjendada ostukorv, et teha ost {eventCurrency} valuutas.',
        finish_payment: 'Lõpeta olemasolev ost {cartCurrency} valuutas',
        clear_cart: 'Tühjenda ostukorv ja tee uus ost {eventCurrency} valuutas'
      },
      eventSubscribeModal: {
        title: 'Registratsioon'
      },
      eventSubscribe: {
        registration: 'Registratsioon',
        register: 'Registreeru',
        earlyBird:
          'Registreeruge ja saate võimaluse osta eksklusiivsed <b class="color--text-blue wsnw">Early bird piletid</b>'
      },
      refund: {
        title: 'TAGASTUS ostu Nr. <div>{number}</div> kohta',
        details: 'Tagastuse teave',
        deadline: 'Tagastust saab taotleda kuni:',
        reason: 'Tagastamise põhjus',
        info: 'Palun pange tähele, et tagasimakse tehakse samale pangakontole või maksekaardile, millelt algne ost sooritati.',
        payment: {
          type: 'Makseviis',
          bank: 'Panga nimi',
          payer: 'Saaja nimi',
          account: 'Konto nr'
        },
        order_tickets: 'Piletite ost',
        ticket_number: 'Piletite nr.',
        ticket_type: 'Piletite tüüp',
        ticket_price: 'Hind',
        ticket_venue: 'Toimumiskoha tasu',
        ticket_service: 'Teenustasu',
        flat_price: 'Piletite netoväärtus (sh KM)',
        total_refund: 'Kokku tagasimakstav summa',
        initiate: 'Taotle tagastust',
        terms: 'Olen tutvunud ja nõustun kasutustingimustega.',
        steps: {
          initiate: 'Tagasimakse algatatud',
          process: 'Tagasimakse töötlemisel',
          compete: 'Tagasimakse lõpetatud'
        },
        success: {
          title: 'Tagasimakse taotlus on edukalt esitatud',
          subtitle:
            'Teie tagasimakse taotlus töödeldakse peagi. Kui tagasimakse on tehtud, saate eraldi e-kirja.'
        },
        payout: {
          title: 'Tagasimakse on pooleli',
          subtitle:
            'Teie tagasimakse taotlus töödeldakse peagi. Kui tagasimakse on tehtud, saate eraldi e-kirja.'
        },
        completed: {
          title: 'Tagasimakse lõpetatud',
          subtitle:
            'Teie tagasimakse on töödeldud. Palun vaadake tagasimakse üksikasju e-kirjast.'
        },
        expired: {
          title: 'Tagasimakse taotluste esitamise tähtaeg on möödunud',
          subtitle:
            'Praegusel hetkel ei ole piletimüüjal luba selle ostu eest tagasimakset teha, kuna määratud päevade arv on möödunud.'
        }
      }
    })
  })
}
